<template>
  <div class="Tests">
    <span class="PageHeader">Администрирование (Студент: {{ userFIO }})</span>

    <router-link
      class="ButtonBack"
      :to="`/admin/sprints/step/3?user_id=${userID}&pid=${pID}&npid=${npID}`"
      >Назад</router-link
    >

    <div class="PLongCard ql-snow" v-if="SelectedJobWithSprints != null">
      <div class="PLTips">
        <span :class="getStep4CardStatus.c">{{ getStep4CardStatus.t }}</span>
        <span
          v-for="(sect, sindx) in getSections(SelectedJobWithSprints?.sections)"
          :key="sindx"
          :class="{
            CStatus: true,
            IsDone: true,
          }"
          >{{ sect }}</span
        >
        <span class="ProjectDifficult">
          <span
            v-for="index in levels[SelectedJobWithSprints?.level]"
            :key="index"
            class="DifficultPainted"
          />
          <span
            v-for="index in 3 - levels[SelectedJobWithSprints?.level]"
            :key="index"
            class="DifficultEmpty"
          />
        </span>
      </div>
      <span class="PLHead">{{ SelectedJobWithSprints?.name }}</span>
      <span class="PLDesc ql-editor" v-html="SelectedJobWithSprints?.description"> </span>
    </div>

    <div class="PCardHeader">
      <span class="FiltersHeader">Спринты выбранной задачи</span>

      <ButtonGradient
        v-if="getStep4CardStatus.t === 'Выполняется'"
        ButtonText="Завершить выполнение задачи"
        @click.native="
          () => {
            SelectedSprint = getSprintsSorted(SelectedJobWithSprints?.JobSprints)[0];
            showModal2 = true;
          }
        "
      />
    </div>

    <div class="sprints-list" v-if="SelectedJobWithSprints != null">
      <div
        class="sprint-block"
        v-for="(sprint, sindx) in getSprintsSorted(SelectedJobWithSprints?.JobSprints)"
        :key="`${sindx}${sprint.id}`"
        @click="
          () => {
            openSprint(sprint, sindx);
          }
        "
      >
        <span class="sprint-block-title"
          >Спринт {{ SelectedJobWithSprints?.JobSprints.length - sindx }} от
          {{ getHumanDate(sprint.sprint_start) }}
          <span class="sprint-block-title-bold" v-if="sindx == 0"
            >до {{ getHumanDateWithTime(sprint.sprint_end) }}</span
          >
        </span>

        <div class="sprint-block-card">
          <span :class="getSprintStatus(sprint).c">{{ getSprintStatus(sprint).t }}</span>
          <div class="sprint-card-desc ql-snow">
            <span class="sprint-card-desc-text">{{ sprint.sprint_task_name }}</span>
            <span
              class="sprint-card-desc-text ql-editor"
              v-html="sprint.sprint_task_desc"
            >
            </span>
          </div>
        </div>

        <div class="sprint-vertical-line-circle"></div>
      </div>

      <div class="sprints-vertical-line"></div>
    </div>

    <div class="ModalWrapper" v-if="SelectedJobWithSprints != null && showModal">
      <div class="SendRequestModal">
        <div
          class="Close"
          @click="
            () => {
              showModal = false;
            }
          "
        ></div>

        <div class="Title">
          <span
            >Спринт {{ SelectedSprint?.sprint_number }} от
            {{ getHumanDate(SelectedSprint?.sprint_start) }}</span
          >
        </div>

        <div class="MSBody">
          <span class="SprintTitle">{{ SelectedSprint?.sprint_task_name }}</span>

          <div class="BlockWithSmallGap">
            <span class="MSTitle">Дата начала cпринта</span>
            <input
              v-if="SelectedSprint?.sprint_start_copy != null"
              type="date"
              name="calendar"
              v-model="SelectedSprint.sprint_start_copy"
            />
          </div>
          <div class="BlockWithSmallGap">
            <span class="MSTitle">Дата окончания cпринта</span>
            <input
              v-if="SelectedSprint?.sprint_end_copy != null"
              type="date"
              name="calendar"
              v-model="SelectedSprint.sprint_end_copy"
            />
          </div>

          <div class="BlockWithSmallGap">
            <span class="MSTitle">Название cпринта</span>
            <input
              v-if="SelectedSprint?.sprint_task_name != null"
              type="text"
              class="TextStyled"
              v-model="SelectedSprint.sprint_task_name"
            />
          </div>

          <div class="BlockWithSmallGap">
            <span class="MSTitle">Описание cпринта</span>
            <div
              class="QEditor QEditorFixHeight"
              style="position: relative; width: 100%; height: 400px"
            >
              <QuillEditor
                ref="QuillEditor"
                toolbar="full"
                theme="snow"
                contentType="html"
                :content="SelectedSprint?.sprint_task_desc"
                @textChange="handleEditorEditChanges($event)"
                @ready="handleEditorEditReady()"
              />
            </div>
          </div>

          <div class="BlockWithSmallGap">
            <span class="MSTitle">Комментарий студента</span>
            <div class="ql-snow" :key="SelectedSprint?.user_comment">
              <span
                class="ql-editor"
                style="padding: 0%; color: #9590c9"
                v-html="
                  SelectedSprint?.user_comment == ''
                    ? 'Пусто'
                    : SelectedSprint?.user_comment
                "
              ></span>
            </div>
          </div>

          <div
            class="BlockWithSmallGap ql-snow"
            v-if="SelectedSprint?.status === 'Проверено'"
          >
            <span class="MSTitle">Комментарий проверяющего</span>
            <span
              class="MSText ql-editor"
              style="width: 100%"
              v-html="SelectedSprint?.moder_comment"
            >
            </span>
          </div>

          <div class="BlockWithSmallGap">
            <span class="MSTitle">Материалы студента</span>
            <div class="MSMaterials" v-if="SelectedSprint?.SprintMaterials?.length != 0">
              <a
                v-for="(mat, mindx) in SelectedSprint?.SprintMaterials"
                :key="mat + mindx"
                :href="fileDownloadProtocolReplace(mat?.url_file)"
                target="_blank"
                >{{ getFilename(mat) }}</a
              >
            </div>
            <span class="MSText" v-else>Пусто</span>
          </div>

          <div
            class="BlockWithSmallGap"
            v-if="
              SelectedSprint?.status !== 'Проверено' &&
              SelectedSprint?.SprintMaterials?.length != 0
            "
          >
            <span class="MSTitle">Ваш комментарий</span>

            <div
              class="QEditor QEditorFixHeight"
              style="position: relative; width: 100%; height: 400px"
            >
              <QuillEditor
                ref="QuillEditor"
                toolbar="full"
                theme="snow"
                contentType="html"
                :content="showModalComment"
                @textChange="handleEditorEditChangesComment($event)"
                @ready="handleEditorEditReadyComment()"
              />
            </div>
          </div>
        </div>

        <template v-if="SelectedSprint?.status !== 'Проверено'">
          <div
            class="ReqButtons noselect"
            v-if="
              !sprintIsActual(SelectedSprint?.sprint_end) &&
              SelectedSprint?.SprintMaterials?.length == 0 &&
              SelectedSprint.sindx == 0
            "
          >
            <span class="CustomButtonDecline" @click="checkSprint(false)"
              >Завершить задачу</span
            >
            <span class="CustomButtonDecline" @click="createSprint"
              >Открыть новый спринт</span
            >
          </div>

          <template v-else>
            <div
              class="ReqButtons noselect"
              v-if="SelectedSprint?.SprintMaterials?.length != 0"
            >
              <ButtonGradient
                ButtonText="Просмотренно"
                @click.native="
                  () => {
                    checkSprint(true);
                  }
                "
              />
            </div>
          </template>
        </template>

        <div class="ReqButtons noselect">
          <ButtonGradient ButtonText="Сохранить" @click.native="updateSprintInfo" />

          <span class="CustomButtonDecline" @click="showModal = false">Отменить</span>
        </div>
      </div>
    </div>

    <div class="ModalWrapper" v-if="SelectedJobWithSprints != null && showModal2">
      <div class="SendRequestModal">
        <div
          class="Close"
          @click="
            () => {
              showModal2 = false;
            }
          "
        ></div>

        <div class="Title">
          <span>Завершение выполнения</span>
        </div>

        <div class="MSBody">
          <span class="SprintTitle">{{ SelectedSprint?.sprint_task_name }}</span>

          <div class="BlockWithSmallGap">
            <span class="MSTitle"
              >Вы уверены, что хотите завершить выполнение данной задачи?</span
            >
          </div>
        </div>

        <div class="ReqButtons noselect">
          <ButtonGradient
            ButtonText="Студент выполнил задачу"
            @click.native="
              () => {
                successTask.showModal = true;
              }
            "
          />
          <ButtonGradient
            ButtonText="Студент снят с выполнения задачи"
            @click.native="
              () => {
                closeTask(false);
              }
            "
          />
        </div>
      </div>
    </div>

    <div
      class="ModalWrapper"
      v-if="showModal2 && successTask.showModal"
      style="z-index: 9999"
    >
      <div class="SendRequestModal">
        <div
          class="Close"
          @click="
            () => {
              successTask.showModal = false;
              successTask.blockTime = '';
              successTask.blockQuality = '';
              successTask.blockResult = '';
              successTask.blockAdditional = '';
              successTask.ready_link = '';
            }
          "
        ></div>

        <div class="Title">
          <span>Отзыв наставника о проделанной работе</span>
        </div>

        <div class="MSBody MoreGap">
          <div class="BlockWithSmallGap">
            <span class="MSTitle"
              >Ссылка на решение <CircleQuestionIcon @click="showTipForBlock(4)"
            /></span>

            <div class="Input-Floating-Label">
              <input
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error':
                    successTask.ready_link === '' ||
                    successTask.ready_link.length === 0 ||
                    !isValidURL(successTask.ready_link),
                }"
                type="text"
                placeholder=""
                autocomplete="off"
                v-model="successTask.ready_link"
              />
              <span class="Input-Text-Length noselect">{{
                successTask.ready_link.length
              }}</span>
            </div>
          </div>

          <div class="BlockWithSmallGap">
            <span class="MSTitle"
              >Время, рекомендуемое наставником
              <CircleQuestionIcon @click="showTipForBlock(0)"
            /></span>

            <div class="Input-Floating-Label">
              <input
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error':
                    successTask.blockTime === '' || successTask.blockTime.length === 0,
                }"
                type="text"
                placeholder=""
                autocomplete="off"
                v-model="successTask.blockTime"
              />
              <span class="Input-Placeholder noselect"
                >Выполнил за: {{ weekTextFormat(getSprintsReadyTime()) }}</span
              >

              <span class="Input-Text-Length noselect">{{
                successTask.blockTime.length
              }}</span>
            </div>
          </div>

          <div class="BlockWithSmallGap">
            <span class="MSTitle"
              >Качество выполненной работы

              <CircleQuestionIcon @click="showTipForBlock(1)" />
            </span>

            <div class="Input-Floating-Label">
              <textarea
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error':
                    successTask.blockQuality === '' ||
                    successTask.blockQuality.length < 10,
                }"
                type="text"
                placeholder=""
                autocomplete="off"
                v-model="successTask.blockQuality"
              />
              <span class="Input-Placeholder noselect">От 1 до 10 и комментарий</span>
              <span class="Input-Text-Length noselect">{{
                successTask.blockQuality.length
              }}</span>
            </div>
          </div>

          <div class="BlockWithSmallGap">
            <span class="MSTitle"
              >Объём выполненных работ <CircleQuestionIcon @click="showTipForBlock(2)"
            /></span>

            <div class="Input-Floating-Label">
              <textarea
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error':
                    successTask.blockResult === '' || successTask.blockResult.length < 10,
                }"
                type="text"
                placeholder=""
                autocomplete="off"
                v-model="successTask.blockResult"
              />
              <span class="Input-Placeholder noselect">От 1 до 10 и комментарий</span>
              <span class="Input-Text-Length noselect">{{
                successTask.blockResult.length
              }}</span>
            </div>
          </div>

          <div class="BlockWithSmallGap">
            <span class="MSTitle"
              >Дополнительно <CircleQuestionIcon @click="showTipForBlock(3)"
            /></span>

            <div class="Input-Floating-Label">
              <textarea
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error':
                    successTask.blockAdditional === '' ||
                    successTask.blockAdditional.length < 10,
                }"
                type="text"
                placeholder="Укажите дополнительную информацию на своё усмотрение*"
                autocomplete="off"
                v-model="successTask.blockAdditional"
              />
              <span class="Input-Text-Length noselect">{{
                successTask.blockAdditional.length
              }}</span>
            </div>
          </div>
        </div>

        <div class="ReqButtons noselect">
          <ButtonGradient @click.native="closeTask(true)" ButtonText="Отправить" />
          <ButtonGradient
            @click.native="
              () => {
                successTask.showModal = false;
                successTask.blockTime = '';
                successTask.blockQuality = '';
                successTask.blockResult = '';
                successTask.blockAdditional = '';
                successTask.ready_link = '';
              }
            "
            ButtonText="Отменить"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  adminGetAllOneUserSprints,
  createSprintOnMonday,
  checkSprint,
  updateSprint,
} from "@/api/sprints.js";

import { QuillEditor } from "@vueup/vue-quill";

import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import CircleQuestionIcon from "@/components/CustomElements/CircleQuestionIcon.vue";

export default {
  components: { ButtonGradient, QuillEditor, CircleQuestionIcon },
  data() {
    return {
      Statuses: [
        "Ожидает рассмотрения",
        "Принята",
        "Отклонена",
        "Отменена пользователем",
      ],
      SortsList: [
        "Сортировка по умолчанию (Новее)",
        "По дате (Старее)",
        "По региону (А-я)",
        "По региону (Я-а)",
        "По вузу (А-я)",
        "По вузу (Я-а)",
        "По cтатусу (При-Ожи-Отк)",
        "По cтатусу (Отк-Ожи-При)",
      ],
      DirectionsList: ["Выберите направление"],
      Filters: {
        SortBy: "Сортировка по умолчанию (Новее)",
        SearchInput: "",
        Direction: "Выберите направление",
      },

      UsersWithData: [],
      ProjectsList: [],
      JobsList: [],
      SelectedJobWithSprints: null,
      SelectedSprint: null,
      SelectedUser: null,

      SelectedReq: null,
      ReqStep: 1,

      levels: {
        Легкий: 1,
        Средний: 2,
        Сложный: 3,
      },

      successTask: {
        showModal: false,
        blockTime: "",
        blockQuality: "",
        blockResult: "",
        blockAdditional: "",
        ready_link: "",
      },

      showModal2: false,

      showModal: false,
      showModalStep: 1,
      showModalComment: "",

      userFIO: "",
    };
  },
  async created() {
    await this.initialization();
  },
  watch: {},
  computed: {
    getStep4CardStatus() {
      if (this.SelectedJobWithSprints?.jobs_worker?.status != null) {
        switch (this.SelectedJobWithSprints?.jobs_worker?.status) {
          case "Успех": {
            return {
              t: "Задача завершена",
              c: "CStatus IsDone",
            };
          }
          case "Провал": {
            return {
              t: "Задача завершена (досрочно)",
              c: "CStatus IsDeclined",
            };
          }
          case "В работе": {
            return {
              t: "Выполняется",
              c: "CStatus IsWaiting",
            };
          }
        }
      }

      return {
        t: "Статус не определен",
        c: "CStatus IsWaiting",
      };
    },

    userID() {
      return this.$route.query.user_id;
    },
    pID() {
      return this.$route.query.pid;
    },
    npID() {
      return this.$route.query.npid;
    },
    jID() {
      return this.$route.query.jid;
    },
  },
  methods: {
    isValidURL(string) {
      // Проверка на null или пустую строку
      if (!string) {
        return false;
      }

      try {
        // Создание объекта URL. Если он некорректный, будет выброшено исключение
        new URL(string);
        return true;
      } catch (e) {
        return false;
      }
    },
    fileDownloadProtocolReplace(url) {
      if (url === "" || url == null) {
        return "#";
      }

      let hasAnyProtocol = url.substring(0, 4); //http

      if (hasAnyProtocol === "http") {
        if (url.substring(0, 5) === "https") {
          return url;
        } else {
          return url.replace("http", "https");
        }
      } else {
        return `https://${url}`;
      }
    },
    showTipForBlock(index) {
      let res = [
        `
          Количество времени, которое должен затратить студент на решение задачи, с учётом высокого уровня вовлечения и средним / ниже среднего уровня подготовки.

          Примеры заполнения:
          1) 2-3 недели
          2) 1 неделя
          3) 10 недель
        `,
        `
          Оценка и критерии:
          1-3: Код трудно читаем и плохо структурирован, что затрудняет работу с ним. Присутствуют явные ошибки в логике программы, включая критические, которые негативно влияют на производительность и корректность результата. Документация отсутствует или недостаточна.
          4-7: Код читаемый, с ним можно работать. Критических ошибок нет, а логика программы соблюдена. Однако присутствуют нарушения в чистоте кода, такие как несоответствие стилевым рекомендациям, избыточные или дублирующиеся конструкции. Обработка исключительных ситуаций минимальна, и тесты могут покрывать не все случаи.
          8-10: Код хорошо структурирован и легко читается, соблюдены общепринятые стилистические рекомендации. Основные исключительные ситуации учтены и обработаны. Логика программы полностью соблюдена, и тесты успешно проходят, включая пограничные и исключительные случаи. Код сопровождается полной и ясной документацией.

          Пример заполнения:
          7/10 баллов. Код читаемый, однако комментарии отсутствуют. С точки зрения безопасности есть проблемы (доступ к информации других людей).
        `,
        `
          Оценка и критерии:
          1-3: Работа требует значительных переделок, исправлений и доработок. Студенту пришлось множество раз вносить изменения в код по комментариям наставника, чтобы привести его в соответствие с требованиями. Часто обнаруживаются новые ошибки или недочёты, требующие дальнейших правок.
          4-7: Работа потребовала некоторых доработок, но в целом была выполнена на приемлемом уровне. Студенту пришлось внести изменения несколько раз по комментариям наставника, чтобы устранить замечания. Основные ошибки были исправлены, однако потребовалось время на улучшение качества и соответствие требованиям.
          8-10: Работа выполнена с минимальными замечаниями. Студенту потребовалось не более одного раза внести небольшие корректировки по комментариям наставника. Изменения были выполнены быстро и точно, без необходимости в дополнительных правках. В дальнейшем работа соответствовала всем требованиям.


          Пример заполнения:
          7/10 баллов. Студенту пришлось доработать качество .py-кода (вынесение конфига из основного кода программы и файл string.py) и изменение telegram-id на user_id.
        `,
        `
          Укажите дополнительную информацию на своё усмотрение:
          - Оригинальность решений: предлагал ли студент нестандартные подходы к решению задачи?
          - Умение выйти за рамки задания: смог ли студент увидеть дополнительные возможности для улучшения результата?
          - Уровень самостоятельности: в какой степени студент мог самостоятельно принимать решения и решать возникающие проблемы?
          - Приобретенные мягкие навыки: например, умение работать в команде, анализировать данные и т.д.
          - Инициативность: проявлял ли студент инициативу в поиске решений, предлагал ли свои идеи или альтернативные подходы?
          - Частота коммуникации: как часто студент обращался к наставнику за помощью, советом или уточнениями?

          Пример заполнения:
          Студент исполнителен, ответственен, коммуникабелен. Заинтересован в дальнейшем профессиональном развитии.
        `,
        `
          Укажите ссылку на решение задачи (Возможно, гитхаб) 

          Пример заполнения:
          https://github.com/OctagonSprints/Octabot
        `,
      ];

      alert(res[index]);
    },

    openSprint(sprint, sindx) {
      this.SelectedSprint = JSON.parse(JSON.stringify(sprint));
      this.SelectedSprint.sprint_start_copy = this.SelectedSprint.sprint_start.split(
        "T"
      )[0];
      this.SelectedSprint.sprint_end_copy = this.SelectedSprint.sprint_end.split("T")[0];
      this.SelectedSprint.sprint_number =
        this.SelectedJobWithSprints?.JobSprints.length - sindx;
      this.SelectedSprint.sindx = sindx;
      this.showModal = true;
    },

    getSections(sects) {
      if (sects == null) {
        return "";
      }

      return sects.map((x) => x.name);
    },

    handleEditorEditChanges(delta) {
      if (this.SelectedSprint != null) {
        this.SelectedSprint.sprint_task_desc = this.$refs.QuillEditor.getHTML();
      }
    },
    handleEditorEditReady() {
      if (this.SelectedSprint != null) {
        this.$refs.QuillEditor.setHTML(this.SelectedSprint.sprint_task_desc);
      }
    },

    handleEditorEditChangesComment(delta) {
      if (this.showModalComment != null) {
        this.showModalComment = this.$refs.QuillEditor.getHTML();
      }
    },
    handleEditorEditReadyComment() {
      if (this.showModalComment != null) {
        this.$refs.QuillEditor.setHTML(this.showModalComment);
      }
    },

    async updateSprintInfo() {
      await updateSprint({
        id: this.SelectedSprint.id,
        sprint_task_name: this.SelectedSprint.sprint_task_name,
        sprint_task_desc: this.SelectedSprint.sprint_task_desc,
        new_start_date: this.SelectedSprint.sprint_start_copy,
        new_end_date: this.SelectedSprint.sprint_end_copy,
        job_is_done: this.SelectedSprint.job_is_done,
      });

      this.showModal = false;

      this.initialization();
    },

    async initialization() {
      let _jobReqList = await adminGetAllOneUserSprints(this.$route.query.user_id);
      let rawList = _jobReqList.data;

      console.log(rawList);
      this.userFIO = `${rawList.surname} ${rawList.name}`;

      let _jobUser = [...rawList?.JobUser];

      // Создаем объект для группировки по project.id или n_project.id
      let groupedData = _jobUser.reduce((acc, obj) => {
        let id = null;
        if (obj?.project?.id != null) {
          id = `p${obj?.project?.id}`;
        } else if (obj?.n_project?.id != null) {
          id = `np${obj?.project?.id}`;
        } else {
          id = "nullproject";
        }

        if (!acc[id]) {
          acc[id] = [];
        }
        acc[id].push(obj);
        return acc;
      }, {});

      let findProject = Object.values(groupedData).find((x) => {
        let firstJob = x?.[0];

        if (this.$route.query.pid !== "null") {
          if (firstJob.project?.id === parseInt(this.$route.query.pid)) {
            return true;
          }
        }

        if (this.$route.query.npid !== "null") {
          if (firstJob.n_project?.id === parseInt(this.$route.query.npid)) {
            return true;
          }
        }

        if (this.$route.query.npid === "null" && this.$route.query.pid === "null") {
          if (firstJob.n_project === null && firstJob.project === null) {
            return true;
          }
        }

        return false;
      });

      this.SelectedJobWithSprints = findProject.find(
        (x) => x.id === parseInt(this.$route.query.jid)
      );
    },

    async createSprint() {
      let res = await createSprintOnMonday({
        jobID: this.SelectedJobWithSprints.id,
        userID: this.SelectedSprint.sprint_user_id,
        prevWasExpired: true,
      });

      this.initialization();
      this.showModal = false;
    },
    async checkSprint(isCheck) {
      let res = await checkSprint({
        cur_sprint_id: this.SelectedSprint.id,
        comment: isCheck
          ? this.showModalComment
          : "Не выполнил спринт, не загрузил материалы",
        job_is_done: !isCheck,
      });

      this.initialization();
      this.showModal = false;
    },
    getSprintsReadyTime() {
      return this.getSprintsSorted(this.SelectedJobWithSprints?.JobSprints).filter(
        (x) => x.SprintMaterials.length !== 0
      ).length;
    },
    weekTextFormat(x) {
      if (x % 10 === 1 && Math.floor(x / 10) !== 1) {
        return `${x} неделя`;
      }

      if ((x % 10 === 2 || x % 10 === 3 || x % 10 === 4) && Math.floor(x / 10) !== 1) {
        return `${x} недели`;
      }

      return `${x} недель`;
    },
    async closeTask(isSuccess) {
      let compiledText = null;
      if (isSuccess) {
        compiledText = `
<b>Отзыв и оценка наставника</b>

<b>Время, затраченное на решение задачи</b> - ${this.weekTextFormat(
          this.getSprintsReadyTime()
        )}.
<b>Время, рекомендуемое наставником на выполнение задачи:</b> ${
          this.successTask.blockTime
        }.
        
<b>Качество выполненной работы:</b>
${this.successTask.blockQuality}
        
<b>Объем выполненных работ:</b>
${this.successTask.blockResult}
        
<b>Дополнительно:</b>
${this.successTask.blockAdditional}
      `;
      }

      let res = await checkSprint({
        cur_sprint_id: this.SelectedSprint.id,
        comment: isSuccess
          ? "Задача была успешно выполнена"
          : "Не выполнил спринт, не загрузил материалы",
        job_is_done: true,
        job_ready_comment: compiledText,
        ready_link: this.successTask.ready_link,
      });

      this.initialization();
      this.showModal2 = false;
    },

    getSprintStatus(sprint) {
      let statusClasses = {
        awaiting: "sprint-card-status IsWaiting",
        done: "sprint-card-status IsDone",
        failed: "sprint-card-status IsDeclined",
      };

      if (!this.sprintIsActual(sprint?.sprint_end)) {
        if (sprint?.SprintMaterials?.length == 0) {
          return { c: statusClasses["failed"], t: "Спринт просрочен" };
        }

        if (sprint?.status === "Не проверено") {
          return { c: statusClasses["awaiting"], t: "Ожидает проверки" };
        }

        if (sprint?.status === "Проверено") {
          return { c: statusClasses["done"], t: "Проверено" };
        }

        return { c: statusClasses["awaiting"], t: "Статус не определен :/" };
      }

      if (sprint?.status === "Не проверено" && sprint?.SprintMaterials?.length > 0) {
        return { c: statusClasses["awaiting"], t: "Ожидает проверки" };
      }

      if (sprint?.status === "Проверено") {
        return { c: statusClasses["done"], t: "Проверено" };
      }

      if (sprint?.SprintMaterials?.length == 0) {
        return {
          c: statusClasses["awaiting"],
          t: "Ожидает подгрузки материалов",
        };
      }

      return {
        c: statusClasses["awaiting"],
        t: "Спринт актуален, статус не определен :/",
      };
    },

    getSprintsSorted(Sprints) {
      if (Sprints == null) {
        return [];
      }

      return Sprints.sort((a, b) => b.id - a.id);
    },

    getFilename(mat) {
      const filePath = mat.url_file;

      // Функция для извлечения имени файла с расширением без чисел до символа "-"
      let getCleanFileNameWithExtension = (filePath) => {
        const parts = filePath.split(/[\\\/]/);
        const fileNameWithExtension = parts[parts.length - 1];
        const fileNameWithoutNumbersBeforeDash = fileNameWithExtension.replace(
          /^\d+-/,
          ""
        );
        return fileNameWithoutNumbersBeforeDash;
      };

      const cleanFileNameWithExtension = getCleanFileNameWithExtension(filePath);

      return cleanFileNameWithExtension;
    },
    sprintIsActual(date_end) {
      if (date_end == null) {
        return false;
      }

      const specifiedDate = new Date(date_end); // Замените "2022-01-01" на вашу заданную дату
      // Получаем текущую дату
      const currentDate = new Date();

      console.log(specifiedDate < currentDate);

      return currentDate < specifiedDate;
    },

    getNormalDate(BDDate) {
      let date = BDDate.split("T")[0].split("-");
      return `${date[2]}.${date[1]}.${date[0]}`;
    },
    getHumanDate(date_start) {
      if (date_start == null || date_start == "") {
        return "";
      }

      let Date = date_start.split("T")[0].split("-");
      return `${Date[2]}.${Date[1]}.${Date[0]}`;
    },
    getHumanDateWithTime(date_end) {
      if (date_end == null || date_end == "") {
        return "";
      }

      let Date = date_end.split("T")[0].split("-");
      let Time = date_end.split("T")[1].split(":");
      return `${Date[2]}.${Date[1]}.${Date[0]} ${Time[0]}:${Time[1]}`;
    },
  },
};
</script>

<style scoped>
@import "./../../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.PCardHeader {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: fit-content;
  gap: 20px;
}
.PCardHeader > * {
  margin-top: auto;
  margin-bottom: auto;
}

.TextStyled {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  padding: 8px;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d3caff40;

  background-color: transparent;
}

.MSBody {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;
}
.MSBody.MoreGap {
  gap: 32px;
}
.BlockWithSmallGap {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
.SprintTitle {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: #656bff;
  text-decoration: underline;
}
.MSTitle {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}
.MSText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #d3caffb2;
}
.MSMaterials {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.MSMaterials > a {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #656bff;
}
.MSTextArea {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #ffffffe5;

  background-color: transparent;
}

.sprints-list {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  padding-left: 22px;

  width: 100%;
  height: fit-content;
}
.sprint-block {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;

  z-index: 2;
}
.sprint-block-title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sprint-block-title-bold {
  font-weight: 700;
}

.sprints-vertical-line {
  position: absolute;
  display: block;

  padding-top: 5px;

  left: 5px;
  bottom: 0;

  width: 1px;
  height: calc(100% - 4px);

  background-color: #514ebe;

  z-index: 1;
}

.sprint-block-card {
  position: relative;
  display: flex;

  cursor: pointer;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  padding: 20px 30px;

  width: 100%;
  height: fit-content;

  border-radius: 20px;
  background: #1e1c42;
}

.sprint-vertical-line-circle {
  position: absolute;
  display: block;

  top: 3px;
  left: -22px;
  width: 11px;
  height: 11px;

  background: white;
  border-radius: 100%;
}

.sprint-card-desc > * {
  margin-top: auto;
  margin-bottom: auto;
}
.sprint-card-desc {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}

.sprint-card-desc-text {
  position: relative;
  display: block;
  width: 100%;
  height: fit-content;
  color: #fff;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  flex-shrink: 1;
  flex-grow: 0;
  text-align: left;
}
.sprint-arrow-right {
  position: relative;
  width: 21px;
  height: auto;

  cursor: pointer;

  aspect-ratio: 1 / 0.7619;
}

.sprint-card-status {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  color: #1dd185;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 4px;
  border: 1px solid #1dd185;
}

.CustomButtonDecline {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  background-color: #656bff40;

  flex-shrink: 0;
}

.ModalWrapper {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);

  z-index: 500;
}

.SendRequestModal {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;

  width: fit-content;
  min-width: 464px;
  height: fit-content;

  max-width: 100%;
  max-height: 98%;

  overflow-y: auto;

  padding: 20px;
  margin: auto;

  background: linear-gradient(224.99deg, #1e1b4a 0.05%, #1f1c4a 48.65%, #31337b 100.05%);
  border-radius: 8px;

  z-index: 10;

  scrollbar-width: thin;
  scrollbar-color: #413e7400 transparent;
}
.SendRequestModal::-webkit-scrollbar {
  width: 0px;
}
.SendRequestModal::-webkit-scrollbar-track {
  background: transparent;
}
.SendRequestModal::-webkit-scrollbar-thumb {
  background-color: #413e7400;
  border-radius: 0px;
  border: 0px solid transparent;
}

.SendRequestModal textarea {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;
  min-height: 48px;

  padding: 12px 8px;
  border-radius: 4px;
  border: 1px solid #d3caff40;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  background-color: transparent;
}

.Close {
  position: absolute;
  display: block;

  top: 0;
  right: 0;

  width: 32px;
  height: 32px;

  background: url("./../../../assets/img/Landing/CloseButtonForRegModal.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  border-radius: 4px;

  background-color: #656bff40;

  z-index: 10;
}
.Title {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  z-index: 5;
}
.Title > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-right: 195px;
}

.ReqInfo {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.RTop,
.RBot {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.RFIO {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}
.RInfo {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #d3caffb2;
}
.RInfo > a {
  position: relative;
  display: block;

  padding-left: 4px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  text-decoration: underline;
  color: #656bff;
}
.RBot > span:nth-child(1) {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  text-decoration: underline;
  color: #656bff;
}
.RBot > span:nth-child(2) {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #ffffffd4;
  max-width: 500px;
}
.RBot > span:nth-child(3) {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #d3caffb2;
}

.ReqButtons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
</style>

<style scoped>
.ProjectDifficult {
  position: relative;
  margin-left: 3.5px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
}
.ProjectDifficult > span:not(:nth-last-child(1)) {
  margin-right: 3px;
}
.ProjectDifficult > span {
  margin-top: auto;
  margin-bottom: auto;
}

.DifficultPainted {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.DifficultEmpty {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.PLongCard {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;

  padding: 20px 30px;
  border-radius: 16px;

  background-color: #1e1b4a;
}
.PLHead {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.PLDesc {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: left;

  color: #c8c3de;
}
.PLTips {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.ButtonBack {
  position: relative;
  display: block;

  cursor: pointer;

  width: fit-content;
  min-width: 250px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  padding: 12px 16px;
  border-radius: 4px;

  color: white;
  background-color: #656bff;
}

.Card {
  position: relative;
  display: flex;

  width: 23.5%;

  height: auto;

  flex-basis: auto;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  transition: all 0.2s ease-out;

  border-radius: 12px;

  background: url("./../../../assets/img/Admin/LogoBG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #1e1b4a;
}
.Card:hover {
  cursor: pointer;
  background: none;
  background-color: #27235c;
}
.Card.Selected {
  background: none;
  background-color: #27235c;
}
.CDate {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin-bottom: 4px;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  text-transform: uppercase;

  color: #d3caff4d;
}
.CFIO {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #fff;
}
.PFIO {
  position: relative;
  display: block;

  margin-bottom: auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #fff;
}
.CInfo {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #d3caffb2;
}
.CStatus,
.PStatus {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  border-radius: 4px;
}
.PStatus {
  margin-top: 52px;
}

.StatusAndDate {
  position: relative;
  display: flex;

  margin-top: 52px;

  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
.StatusAndDate > * {
  margin-top: auto;
  margin-bottom: auto;
}

.CDateOnRight {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #d3caff4d;
}

.IsWaiting {
  color: #f9dc5c;
  background-color: #f9dc5c26;
  border: 1px solid #f9dc5c26;
}
.IsAccepted {
  color: #25ed69;
  background-color: #25ed6926;
  border: 1px solid #25ed6926;
}
.IsDeclined {
  color: #ed254e;
  background-color: #ed254e26;
  border: 1px solid #ed254e26;
}

.IsDone {
  color: #d3caffb2;
  background-color: #27235c;
  border: 1px solid #27235c;
}

.ReqCards {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px 2%;
}

.Tests {
  position: relative;

  padding-top: 60px;
  padding-left: 156px;
  padding-right: 156px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;

  color: white;
}

.PageHeader {
  position: relative;
  display: block;

  margin-bottom: 20px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
}
.FiltersHeader {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.Filters {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  gap: 40px;

  width: 100%;
  height: fit-content;
}
.FiltersBlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  width: auto;
  height: fit-content;
}
.StatusesBlock,
.SortBy {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  width: auto;
  height: fit-content;
}
.StatusesBlock > span {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid #656bff;
  background-color: transparent;
  transition: all 0.2s ease-out;
}
.StatusesBlock > span.selected,
.StatusesBlock > span:hover {
  background-color: #656bff;
  border: 1px solid transparent;
}

.SearchInput {
  position: relative;
  display: block;

  padding: 0.5em 1em 0.5em 1em;

  width: 378px;
  height: fit-content;

  border-radius: 4px;
  border: 1px solid #d3caff40;

  background-color: transparent;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #fff;
}
.SearchInput::placeholder,
.SearchInput::-ms-input-placeholder {
  color: #d3caff73;
}

.SelectInput {
  position: relative;
  display: inline-block;

  cursor: pointer;

  padding: 0.5em 3.5em 0.5em 1em;
  padding-right: 45px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid #656bff;
  background-color: transparent;
  transition: all 0.2s ease-out;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}
.SelectInput::-ms-expand {
  display: none;
}
.SelectInput:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  outline: 0;
}
.SelectInput:hover {
  background-color: #656bff;
  border: 1px solid transparent;
}

.ResetFilters {
  position: relative;
  display: inline-block;

  cursor: pointer;

  padding: 0.5em 1em 0.5em 1em;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #656bff;
  transition: all 0.2s ease-out;
}
.ResetFilters:hover {
  background-color: transparent;
  border: 1px solid #656bff;
}
</style>

<style scoped>
.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
textarea.Input-styled {
  min-height: 240px;
}

.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Input-Text-Length {
  position: absolute;
  display: block;

  bottom: 0;
  right: 18px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, 50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Text-Length {
  color: #656bff;
}
</style>
